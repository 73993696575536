.lm-timeline {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.lm-timeline-data {
    position: relative;
    flex: 1;
}

.lm-timeline-header {
    display: none;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    z-index: 1;
    box-sizing: border-box;
}

.lm-timeline-header[data-type="monthly"] {
    display: flex;
}

.lm-timeline-year {
    font-size: 2em;
    display: inline-block;
}

.lm-timeline-month {
    font-size:0.8em;
    position:relative;
    top:6px;
}

.lm-timeline-navigation {
    text-align: center;
}

.lm-timeline-navigation div {
    display: inline-block;
    padding-right: 12px;
    padding-left: 12px;
    margin-left: 4px;
    text-align: center;
    cursor: pointer;
}

.lm-timeline-navigation i {
    font-size: 1.5rem !important;
    line-height: 32px;
    display:inline-block;
    width: 32px;
    height: 32px;
    padding: 12px;
    cursor: pointer;
    user-select: none;
}

.lm-timeline-data:empty:before {
    content: attr(data-message);
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
}

.lm-timeline-item {
    padding: 20px;
    position: relative;
    border-width: 0;
    border-style: solid;
    border-color: var(--lm-border-color, #ccc);
}

.lm-timeline-item[data-bullet]::before {
    content: attr(data-bullet);
    width: 100px;
    height: 30px;
    position: absolute;
}

.lm-timeline-item[data-bullet] .lm-timeline-title::before {
    content: "";
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border-width: 3px;
    border-style: solid;
    border-color: var(--lm-main-color-deep, #1f64e1);
    display: inline-block;
    box-sizing: border-box;
    background-color: #fff;
    margin: 1px;
    position: absolute;
}

.lm-timeline-subtitle {
    color: #aaa;
    font-size: 0.8em;
}

.lm-timeline-data::-webkit-scrollbar {
    height: 12px;
}

.lm-timeline-data::-webkit-scrollbar {
    width: 12px;
}

.lm-timeline-data::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.lm-timeline-data::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

/** Align to the left */

.lm-timeline-data[data-align="left"] {
    padding: 15px 0 15px 125px;
    overflow-y: auto;
}

.lm-timeline-data[data-align="left"] .lm-timeline-item {
    border-left-width: 2px;
}

.lm-timeline-data[data-align="left"] .lm-timeline-item[data-bullet]::before {
    left: -20px;
    margin: 0 0 0 -105px;
    text-align: right;
}

.lm-timeline-data[data-align="left"] .lm-timeline-item[data-bullet] .lm-timeline-title::before {
    left: -10px;
}

/** Align to the right */

.lm-timeline-data[data-align="right"] {
    text-align: right;
    padding: 15px 125px 15px 0;
    overflow-y: auto;
}

.lm-timeline-data[data-align="right"] .lm-timeline-item {
    border-right-width: 2px;
}

.lm-timeline-data[data-align="right"] .lm-timeline-item[data-bullet]::before {
    right: 0;
    margin: 0 -105px 0 0;
}

.lm-timeline-data[data-align="right"] .lm-timeline-item[data-bullet] .lm-timeline-title::before {
    right: -10px;
}

/** Top **/
.lm-timeline-data[data-align="top"] {
    display: flex;
    padding: 50px 0 0 0;
    overflow-x: auto;
}

.lm-timeline-data[data-align="top"] .lm-timeline-item {
    border-top-width: 2px;
    min-width: 200px;
}

.lm-timeline-data[data-align="top"] .lm-timeline-item[data-bullet]::before {
    left: 0;
    top: 0;
    margin: -30px 0 0 20px;
}

.lm-timeline-data[data-align="top"] .lm-timeline-item[data-bullet] .lm-timeline-title::before {
    top: -10px;
}

/** Bottom **/

.lm-timeline-data[data-align="bottom"] {
    display: flex;
    padding: 0 0 50px 0;
    overflow-x: auto;
}

.lm-timeline-data[data-align="bottom"] .lm-timeline-item {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    border-bottom-width: 2px;
    min-width: 200px;
}

.lm-timeline-data[data-align="bottom"] .lm-timeline-item[data-bullet]::before {
    left: 0;
    bottom: -40px;
    margin: 0 0 0 20px;
}

.lm-timeline-data[data-align="bottom"] .lm-timeline-item[data-bullet] .lm-timeline-title::before {
    bottom: -10px;
}